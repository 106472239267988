<template>
  <div>
    <div class="box" :class="[isBlue ? 'bluebg' : 'greenbg']">
      <div class="price" :class="[isYellow ? 'yellow' : '']">{{ price }}</div>
      <div class="text">{{ unit }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    price: {
      type: String,
    },
    unit: {
      type: String,
    },
    isYellow: {// 字体颜色
      type: Boolean,
      default: false
    },
    isBlue: {// 背景色
      type: Boolean,
      default: true
    }

  },
  data() {
    return {

    };
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
};
</script>

<style scoped lang="scss">
.box {
  //   width: 368px;
  // height: 290px;
  width: 248px;
  height: 156px;
  background-image: url(../../../assets/image/pledge/blue_box.png);
  background-size: 100%;
  position: relative;
  border-radius: 12px;

}

.bluebg {
  background-image: url(../../../assets/image/pledge/blue_box.png);
  box-shadow: 0px 14px 22px 0px #A1CDFF


}

.greenbg {
  background-image: url(../../../assets/image/pledge/green_box.png);
  box-shadow: 0px 14px 22px 0px #89DFD1



}

.price {
  font-size: 40px;
  font-family: DIN-Bold, DIN;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  height: 92px;
  line-height: 120px;
}
.yellow {
  color: #FFF9AB;
}
.text {
  font-size: 24px;
  font-family: PingFangSC-Medium-, PingFangSC-Medium;
  font-weight: normal;
  color: #FFFFFF;
  text-align: center;

}
</style>
