import {
	post,
	get
} from '@/utils/http'
export function land_data_retrieve(params) {
	return post({
		url: '/service-insight/land/data/retrieve',
		params
	})
}

//地块列表地区下拉框
export function land_data_area(params) {
	return get({
		url: '/service-insight/land/data/area',
		params
	})
}

//获取行政区
export function land_data_query_district(params) {
	return get({
		url: '/service-insight/land/data/query_district',
		params
	})
}

//地块列表查询分页
export function land_data_pageList(params) {
	return post({
		url: '/service-insight/land/data/pageList',
		params
	})
}
//保存模版
export function land_data_is_del_template(params) {
	return post({
		url: '/service-insight/land/data/is_del_template',
		params
	})
}
//查询模版
export function land_data_query_template(params) {
	return get({
		url: '/service-insight/land/data/query_template',
		params
	})
}

//取消或收藏关注
export function land_data_is_cancel_favorite(params) {
	return post({
		url: '/service-insight/land/data/is_cancel_favorite',
		params
	})
}

//地块详情
export function land_data_details(params) {
	return post({
		url: '/service-insight/land/data/details',
		params
	})
}
//通过地块获取经纬度
export function land_data_query_center(params) {
	return get({
		url: '/service-insight/land/data/query_center',
		params
	})
}

//我的关注
export function land_data_favorite_group_info(params) {
	return get({
		url: '/service-insight/land/data/favorite_group_info',
		params
	})
}
//下拉模糊搜索
export function land_data_land_name_options(params) {
	return post({
		url: '/service-insight/land/data/land_name_options',
		params
	})
}
//通过landPosition获取经纬度
export function land_data_position_lat_lng(params) {
	return get({
		url: '/service-insight/land/data/position_lat_lng',
		params
	})
}

//地图概览
export function land_data_map_overview(params) {
	return post({
		url: '/service-insight/land/data/map_overview',
		params
	})
}

//地图概览统计横幅
export function land_data_map_overview_statistics(params) {
	return post({
		url: '/service-insight/land/data/map_overview/statistics',
		params
	})
}

//地图概览统计列表
export function land_data_map_overview_list(params) {
	return post({
		url: '/service-insight/land/data/map_overview/list',
		params
	})
}

